@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-link {
    position: relative;
    text-decoration: none;
    color: inherit;
  }
  
  .nav-link::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #f5f5f5; /* Set the color of the border */
    transition: width 0.3s ease; /* Adjust the transition speed as needed */
  }
  
  .nav-link:hover::after {
    width: 100%; /* Expand the border on hover */
  }  